import React, {Fragment, useState, useEffect, useContext} from 'react';
import { Typography, Stack, Grid, Box } from '@mui/material';
import Container from '@mui/material/Container';
import Link from 'next/link';
import Image from 'next/image';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import logoWhite2 from "../../public/images/clubr_logo.png";
import ListItem from '@mui/material/ListItem'
import BusinessIcon from '@mui/icons-material/Business';
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

export default function Footer() {
    
    const theme = useTheme();
    const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
    const [themeCss, setThemeCss] = useState(null);
    const [aboutData, setAboutData] = useState('');
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
        setThemeCss(custom_css);
        setLoading(false);
        setAboutData(siteData && siteData ? siteData.club_inner[0].description: '');
    }, [siteData, themeData]);

    const footerContainer = {
        py:{md:10, sm:7, xs:5},
        background: theme.palette.background.footer,
        width:'100%',
        '& img':{
        height:'100%'
        },
        '& a':{
            // color: theme.palette.background.alternate,
            fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
            fontSize:12,
            fontWeight:300,
            cursor:'pointer',
            color: theme.palette.primary.contrastText,
        },
        '& p':{
            fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
            fontSize:14,
            fontWeight:300,
            color: theme.palette.primary.contrastText,
        },
        '& h4':{
            fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
            fontSize:24,
            fontWeight:500,
            pb:3,
            color: theme.palette.primary.title,
        },
    }

    const iconStyle = {
        background: themeCss ? themeCss.theme_main_color : '#fff',
        color: themeCss ? themeCss.theme_primary_font_color : '#fff',
        fontSize:`12px !important`,
        width:25,
        height:25,
        p:0.5,
        mr:1
    }

    const bottomNavLinks = {
    '& ul': {
      display: 'block',
      padding: 0,
      '& li': {
        display: 'inline',
        wordBreak: 'break-all',
        '& a': {
          fontSize: 12,
          color: theme.palette.primary.contrastText,
        }
      }
    }
    };
    
    const footerBottomSection = {
        background: theme.palette.background.footer,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',        
        py: 0.5,
        '& h6':{
            fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
            fontSize:{md:14, sm:12, xs:12},
            fontWeight:300,
            color: theme.palette.primary.contrastText,
            textTransform:'capitalize'
        },
        '& a':{
            color: theme.palette.primary.contrastText,
            fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
            fontSize:{md:16, sm:14, xs:14},
            fontWeight:300,
        },
    };

    const SHOW_MORE_TEXT_LENGTH = 200; 

  return (
    <Fragment>
        {loading ? (
            ''
        ):(
            <Fragment>
                {siteData&&siteData.club_inner[0].events_only == 0 ? (
                <Typography component="div" sx={footerContainer} className="desktopMode">
                    <Container maxWidth="lg" >
                        <Grid container spacing={{md:8, sm:6, xs:6}}>
                            <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5}>
                                <Typography component={'div'}>
                                    <Link href={'/'}>
                                        <Image 
                                            src={siteData && siteData ? siteData.club_inner[0].logo_path : ''} 
                                            alt="..." 
                                            width={80} 
                                            height={55}
                                            sizes="(max-width: 325px) 100vw"
                                            style={{objectFit: "contain"}}	
                                        />
                                    </Link>
                                    <Typography component="p" sx={{mt:4}}>
                                    {aboutData && aboutData.substring(0, SHOW_MORE_TEXT_LENGTH)+'...'}
                                    </Typography>
                                </Typography>
                            </Grid>                             
                            <Grid item xs={12} sm={12} md={2.25} lg={2.25} xl={2.25}>
                                <Typography component={'div'}>
                                    <Typography variant="h4">Quick Links</Typography>
                                    <Typography component="ul" sx={{listStyleType:'none', pl:0}}>                                
                                        {siteData && siteData.sections[0].menus.split(",").indexOf("menu1") >= 0 && (
                                            <li style={{marginBottom:10}}><Link href='/about'>About&nbsp;Us</Link></li>
                                        )}
                                        {siteData && siteData.sections[0].menus.split(",").indexOf("menu2") >= 0 && (
                                            <li style={{marginBottom:10}}><Link href='/events'>Events</Link></li>
                                        )}
                                        {siteData && siteData.sections[0].menus.split(",").indexOf("menu7") >= 0 && (
                                            <li style={{marginBottom:10}}><Link href='/deals'>Deals</Link></li>
                                        )}
                                        {siteData && siteData.sections[0].menus.split(",").indexOf("menu6") >= 0 && (
                                            <li style={{marginBottom:10}}><Link href='/menus'>Menu</Link></li>
                                        )}
                                        {siteData && siteData.sections[0].menus.split(",").indexOf("menu8") >= 0 && (
                                            <li style={{marginBottom:10}}><Link href='/gallery'>Gallery</Link></li>
                                        )}
                                        {siteData && siteData.sections[0].menus.split(",").indexOf("menu4") >= 0 && (
                                            <li style={{marginBottom:10}}><Link href='/shop'>Merchandise</Link></li>
                                        )}
                                        {siteData && siteData.sections[0].menus.split(",").indexOf("menu5") >= 0 && (
                                            <li style={{marginBottom:10}}><Link href='/contact'>Contact&nbsp;Us</Link></li>
                                        )}
                                        
                                        <li style={{marginBottom:10}}><Link href='/privacy-policy'>Privacy&nbsp;Policy</Link></li>                                    
                                        <li style={{marginBottom:10}}><Link href='/refund-policy'>Refund&nbsp;Policy</Link></li>                                    
                                        <li style={{marginBottom:10}}><Link href='/terms-conditions'>Terms &&nbsp;Conditions</Link></li>                                        
                                    </Typography>
                                </Typography>
                            </Grid> 
                            <Grid item xs={12} sm={12} md={2.75} lg={2.75} xl={2.75}>
                                <Typography component={'div'}>
                                    <Typography variant="h4">Timings</Typography>
                                    <Typography component="ul" sx={{listStyleType:'none', pl:0}}>
                                        <li>
                                            <Typography component="p">
                                                WeekDays :&nbsp;
                                                {siteData && siteData.timings[0].start_time
                                                    ? siteData && siteData.timings[0].start_time + " - "
                                                    : ""}
                                                {siteData && siteData.timings[0].end_time
                                                    ? siteData && siteData.timings[0].end_time
                                                    : ""}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography component="p">
                                            WeekEnds :&nbsp;
                                                {siteData && siteData.timings[1].start_time
                                                    ? siteData && siteData.timings[1].start_time + " - "
                                                    : ""}
                                                {siteData && siteData.timings[1].end_time
                                                    ? siteData && siteData.timings[1].end_time
                                                    : ""}
                                            </Typography>
                                        </li>
                                    </Typography>
                                </Typography>

                                {
                                siteData&&siteData.club_inner[0].social_facebook_link || 
                                siteData&&siteData.club_inner[0].social_twitter_link || 
                                siteData&&siteData.club_inner[0].social_instagram_link || 
                                siteData&&siteData.club_inner[0].social_youtube_link ? ( 
                                    <Typography component={'div'} sx={{mt:4}}>
                                        <Typography variant="h4">Social Links</Typography>
                                        <Typography component="ul" sx={{listStyleType:'none', pl:0}}>
                                        <Stack flexDirection="row" sx={{
                                            width: '100%',
                                            display: 'flex',
                                        //   justifyContent: 'space-between'
                                        }}>
                                            {siteData&&siteData.club_inner[0].social_facebook_link ? (
                                                <Link href={siteData&&siteData.club_inner[0].social_facebook_link} style={{marginRight:15}}>
                                                   <FacebookRoundedIcon />
                                                </Link>
                                            ):("")}

                                            {siteData&&siteData.club_inner[0].social_twitter_link ? (
                                                <Link href={siteData&&siteData.club_inner[0].social_twitter_link} style={{marginRight:15}}>
                                                    <TwitterIcon />
                                                </Link>
                                            ):("")}

                                            {siteData&&siteData.club_inner[0].social_instagram_link ? (
                                                <Link href={siteData&&siteData.club_inner[0].social_instagram_link} style={{marginRight:15}}>
                                                    <InstagramIcon />
                                                </Link>
                                            ):("")}

                                            {siteData&&siteData.club_inner[0].social_youtube_link ? (
                                                <Link href={siteData&&siteData.club_inner[0].social_youtube_link} style={{marginRight:15}}>
                                                    <YouTubeIcon />
                                                </Link>
                                            ):("")}
                                            {siteData&&siteData.club_inner[0].social_linkedin_link ? (
                                                <Link href={siteData&&siteData.club_inner[0].social_linkedin_link} style={{marginRight:15}}>
                                                    <LinkedInIcon />
                                                </Link>
                                            ):("")}
                                            {siteData&&siteData.club_inner[0].social_pintrest_link ? (
                                                <Link href={siteData&&siteData.club_inner[0].social_pintrest_link} style={{marginRight:15}}>
                                                    <PinterestIcon />
                                                </Link>
                                            ):("")}
                                            {siteData&&siteData.club_inner[0].social_whatsapp_link ? (
                                                <Link href={siteData&&siteData.club_inner[0].social_whatsapp_link} style={{marginRight:15}}>
                                                    <WhatsAppIcon />
                                                </Link>
                                            ):("")}
                                        </Stack>
                                        </Typography>
                                    </Typography>
                                ):('')}
                            </Grid>   
                            <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
                                <Typography component={'div'}>
                                    <Typography variant="h4">Contact Us</Typography>
                                    <Typography component="ul" sx={{listStyleType:'none', pl:0}}>
                                        <li style={{marginBottom:10}}>
                                            <Typography component="p" sx={{display:'flex', mb:1}}>
                                                <BusinessIcon sx={iconStyle} />{siteData&&siteData.club_inner[0].official_name}
                                            </Typography>
                                            <Typography component="p" sx={{display:'flex', ml:4}}>
                                                {siteData && siteData.club_inner[0].gst_number ? siteData&&siteData.club_inner[0].gst_number : ''}
                                            </Typography>
                                        </li>
                                        <li style={{marginBottom:10}}>
                                            <Typography component="p" sx={{display:'flex'}}>
                                                <EmailIcon sx={iconStyle} />{siteData&&siteData.club_inner[0].restaurantContactEmail}
                                            </Typography>
                                        </li>
                                        <li style={{marginBottom:10}}>
                                            <Typography component="p" sx={{display:'flex'}}>
                                                <PhoneIcon sx={iconStyle} />{siteData&&siteData.club_inner[0].restaurantContactPhone}
                                            </Typography>
                                        </li>
                                        
                                    </Typography>
                                </Typography>
                            </Grid> 
                        </Grid>
                    </Container>
                </Typography>
                ):('')} 
                {siteData&&siteData.club_inner[0].events_only == 0 ? (
                <Typography component="div" sx={footerContainer} className="mobileMode">
                    <Container maxWidth="lg" >
                        <Grid Container>
                            <Grid item xs={12} sm={12}>
                                <Typography component={'div'} sx={{mb:2}}>
                                    <Link href={'/'}>
                                        <Image 
                                            src={siteData && siteData ? siteData.club_inner[0].logo_path : ''} 
                                            alt="..." 
                                            width={50} 
                                            height={32} 
                                            sizes="(max-width: 325px) 100vw"
                                            style={{objectFit: "contain"}}	
                                        />
                                    </Link>
                                </Typography>
                            </Grid>                             
                            <Grid item xs={12} sm={12}>
                                <Typography component={'div'} sx={bottomNavLinks}>
                                <ul>
                                    {siteData && siteData.sections[0].menus.split(",").indexOf("menu1") >= 0 && (
                                        <li style={{fontSize:9, color:'#989898'}}><Link href='/about' style={{fontSize:14}}>About Us</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    )}
                                    {siteData && siteData.sections[0].menus.split(",").indexOf("menu2") >= 0 && (
                                        <li style={{fontSize:9, color:'#989898'}}><Link href='/events' style={{fontSize:14}}>Events</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    )}
                                    {siteData && siteData.sections[0].menus.split(",").indexOf("menu7") >= 0 && (
                                        <li style={{fontSize:9, color:'#989898'}}><Link href='/deals' style={{fontSize:14}}>Deals</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    )}
                                    {siteData && siteData.sections[0].menus.split(",").indexOf("menu6") >= 0 && (
                                        <li style={{fontSize:9, color:'#989898'}}><Link href='/menus' style={{fontSize:14}}>Menu</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    )}
                                    {siteData && siteData.sections[0].menus.split(",").indexOf("menu8") >= 0 && (
                                        <li style={{fontSize:9, color:'#989898'}}><Link href='/gallery' style={{fontSize:14}}>Gallery</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    )}
                                    {siteData && siteData.sections[0].menus.split(",").indexOf("menu4") >= 0 && (
                                        <li style={{fontSize:9, color:'#989898'}}><Link href='/shop' style={{fontSize:14}}>Merchandise</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    )}
                                    {siteData && siteData.sections[0].menus.split(",").indexOf("menu5") >= 0 && (
                                        <li style={{fontSize:9, color:'#989898'}}><Link href='/contact' style={{fontSize:14}}>Contact Us</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    )}
                                    <li style={{fontSize:9, color:'#989898'}}><Link href='/privacy-policy' style={{fontSize:14}}>Privacy Policy</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    <li style={{fontSize:9, color:'#989898'}}><Link href='/refund-policy' style={{fontSize:14}}>Refund Policy</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</li>
                                    <li style={{fontSize:9, color:'#989898'}}><Link href='/terms-conditions' style={{fontSize:14}}>Terms & Conditions</Link></li>
                                </ul>
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Typography component="hr" sx={{borderColor:`#1e1e1e !important`, height:1, width:'100%'}} />
                        <Grid container alignItems="center">
                            <Grid item xs={6} sm={6}>
                                {
                                siteData&&siteData.club_inner[0].social_facebook_link || 
                                siteData&&siteData.club_inner[0].social_twitter_link || 
                                siteData&&siteData.club_inner[0].social_instagram_link || 
                                siteData&&siteData.club_inner[0].social_youtube_link ? ( 
                                <Stack
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                > 
                                    {siteData&&siteData.club_inner[0].social_facebook_link ? (
                                        <ListItem sx={{px:0}}>
                                            <Link href={siteData&&siteData.club_inner[0].social_facebook_link}>
                                                <FacebookRoundedIcon />
                                            </Link>
                                        </ListItem>
                                    ):("")}

                                    {siteData&&siteData.club_inner[0].social_twitter_link ? (
                                        <ListItem sx={{px:0}}>
                                            <Link href={siteData&&siteData.club_inner[0].social_twitter_link}>
                                                <TwitterIcon />
                                            </Link>
                                        </ListItem>
                                    ):("")}

                                    {siteData&&siteData.club_inner[0].social_instagram_link ? (                                                
                                        <ListItem sx={{px:0}}>
                                            <Link href={siteData&&siteData.club_inner[0].social_instagram_link}>
                                                <InstagramIcon />
                                            </Link>
                                        </ListItem>
                                    ):("")}

                                    {siteData&&siteData.club_inner[0].social_youtube_link ? (
                                        <ListItem sx={{px:0}}>
                                            <Link href={siteData&&siteData.club_inner[0].social_youtube_link}>
                                                <YouTubeIcon />
                                            </Link>
                                        </ListItem>
                                    ):("")}
                                </Stack>
                                   
                                ):('')}
                            </Grid>
                        </Grid>
                    </Container>
                </Typography> 
                ):('')}  
                <Typography component="div" sx={footerBottomSection}>                    
                    <Typography variant="h6">Powered by </Typography>
                    <Link href="https://bit.ly/48n9hZh" target="_blank">                        
                    <Image 
                        src={logoWhite2} 
                        alt="..." 
                        width={75} 
                        height={35} 
                        sizes="(max-width: 325px) 100vw"
                        style={{objectFit: "contain", marginLeft:'10px'}}	
                    />           
                    </Link>                      
                </Typography>
            </Fragment>
        )}
    </Fragment>
  )}